import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../prismic/linkResolver'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Slices from '../components/Slices'
import Wrapper from '../components/Wrapper'
import Grid from '../components/Grid'
import Pill from '../components/Pill'
import MoreCases from '../components/MoreCases'
import UnderlineText from '../components/UnderlineText'
import InternalLink from '../components/InternalLink'
import ParseSEOData from '../components/ParseSEOData'

export const query = graphql`
  query CaseQuery($uid: String, $lang: String) {
    prismic {
      allCases(uid: $uid, lang: $lang) {
        edges {
          node {
            _meta {
              lang
            }
            title
            description
            meta_description
            meta_title
            share_image
            category {
              ... on PRISMIC_Category {
                title
                description
                _meta {
                  uid
                  type
                  lang
                }
              }
            }
            body {
              ... on PRISMIC_CaseBodyGallery {
                type
                label
                primary {
                  image_first
                  image_last
                  image_lastSharp{
                    childImageSharp {
                      sizes {
                        aspectRatio
                      }
                    }
                  }
                  image_firstSharp {
                    childImageSharp {
                      sizes {
                        aspectRatio
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Case = (info) => {
  const {data} = info
  const uid = get(info,'pageContext.uid')
  const doc = data.prismic.allCases.edges.slice(0,1).pop();
  if (!doc) return null;

  const node = get(doc,'node')
  const title = get(node, 'title[0].text')
  let description = get(node, 'description')
  if(description){
    description = RichText.render(description, linkResolver)
  }
  const category = get(node, 'category')
  const lang = get(node, '_meta.lang');

  return (
    <div className="case">
      {/* <ParseSEOData data={doc.node} /> */}
      <article className="case-description">
        <Wrapper>
          <Grid>
            <header>
              <h2 className="tt--u co--light mb--xs">
                <InternalLink title="Inspiration" uid="inspiration" type="inspiration" lang={get(category, '_meta.lang')}>
                  <UnderlineText text="Inspiration" />
                </InternalLink>
              </h2>
              <h1 className="fs--l mb--s">{title}</h1>
              <div className="paragraph-max-width">
              {description &&
                <div className="co--light lh--m mb--m rich-text">{description}</div>
              }
              {category &&
                <InternalLink title={get(category,'title[0].text')} uid={get(category,'_meta.uid')} type={get(category,'_meta.type')} lang={get(category, '_meta.lang')}>
                  <Pill>{get(category,'title[0].text')}</Pill>
                </InternalLink>
              }
            </div>              
            </header>

          </Grid>
        </Wrapper>
      </article>
      <Slices allSlices={doc.node.body} />
      <MoreCases notIn={uid} lang={lang} />
    </div>
  );
}


Case.query = query;

export default Case;