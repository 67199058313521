import React from "react"
import get from "lodash/get"
import { graphql, StaticQuery } from "gatsby"
import CaseTeaser from "./CaseTeaser"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../prismic/linkResolver"
import Carrousel from "./Carrousel"
import { defaultLang } from "../../prismic-configuration"
import getTranslations from "../translations"

export const query = graphql`
  query {
    prismic {
      allCases(sortBy: rank_DESC) {
        edges {
          node {
            title
            description
            featured_image_portrait
            featured_detail_image
            _meta {
              uid
              type
              lang
            }
          }
        }
      }
    }
  }
`

const MoreCases = props => {
  const { notIn, lang } = props
  const t = getTranslations(lang)
  const slides = []
  
  return (
    <StaticQuery
      query={query}
      render={data => {
        const allCases = get(data, "prismic.allCases.edges")
        const langCases = allCases.filter(caseData => {
          return caseData.node._meta.lang === lang;
        });
        langCases.map((caseData, index) => {
          const node = get(caseData, "node")
          const title = get(node, "title[0].text")
          const prismicImage = get(node, "featured_image_portrait")
          const detailPrismicImage = get(node, "featured_detail_image")
          const meta = get(node, "_meta")
          let description = get(node, "description")
          if (description) {
            description = RichText.render(description, linkResolver)
          }
          if (meta.uid !== notIn) {
            slides.push(
              <CaseTeaser
                title={title}
                prismicImage={prismicImage}
                detailPrismicImage={detailPrismicImage}
                meta={meta}
                key={index}
                description={description}
              />
            )
          }
        })
        return (
          <div className="slice">
            <Carrousel title={t.cases.other_cases} slides={slides} />
          </div>
        )
      }}
    />
  )
}

export default MoreCases
